import {Button, ButtonProps} from "@mui/material";

type AuthProviderButtonProps = ButtonProps

export const AuthProviderButton = ({children, onClick, sx, startIcon,}: AuthProviderButtonProps) => {
    return <Button
        startIcon={startIcon}
        sx={{
            padding: "1.5rem",
            borderRadius: "10px",
            backgroundColor: "white",
            color: "black",
            textTransform: 'none',
            height: "2.5rem",
            display: "flex",
            alignItems: 'center',
            justifyContent: 'start',
            "&:disabled": {
                opacity: 0.5,
            },
            ...sx,
        }}
        onClick={onClick}
    >
        {children}
    </Button>
}