import React from "react";
import {
  Explore,
  ExploreOutlined,
  Home,
  HomeOutlined,
  RssFeed,
  RssFeedOutlined,
  WorkspacePremium,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";
import { NavigationLink } from "./NavigationLink";
import { NavigationButton } from "./NavigationButton";
import { NavigationLinkText } from "./NavigationLinkText";
import { useRouter } from "next/router";
import { NavigationChangeTheme } from "./NavigationChangeTheme";
import { NavigationRailWrapper } from "./NavigationRailWrapper";
import { NavigationRailActionsWrapper } from "./NavigationRailAction";

const navigationItems = [
  {
    href: "/",
    icon: HomeOutlined,
    iconActive: Home,
    text: "Home",
  },
  {
    text: "Explore",
    icon: ExploreOutlined,
    iconActive: Explore,
    href: "/explore",
  },
  {
    text: "Blog",
    icon: RssFeedOutlined,
    iconActive: RssFeed,
    href: "/blog",
  },
  {
    text: "Upgrade",
    icon: WorkspacePremiumOutlined,
    iconActive: WorkspacePremium,
    href: "/payment",
  },
];

export const NavigationRail = () => {
  const router = useRouter();

  return (
    <NavigationRailWrapper>
      <NavigationRailActionsWrapper>
        {navigationItems.map((navigationItem, i) => {
          return (
            <NavigationLink key={i} href={navigationItem.href}>
              <NavigationButton
                Icon={navigationItem.icon}
                IconActive={navigationItem.iconActive}
                isActive={router.asPath === navigationItem.href}
              />

              <NavigationLinkText>{navigationItem.text}</NavigationLinkText>
            </NavigationLink>
          );
        })}
      </NavigationRailActionsWrapper>

      <NavigationChangeTheme />
    </NavigationRailWrapper>
  );
};
