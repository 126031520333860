import { Paper, Stack } from "@mui/material";
import { NavigationRail } from "../../menu/material/NavigationRail/NavigationRail";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { IMaterialLayout } from "../../../../interfaces/globalComponents/layout/material/IMaterialLayout";
import { NavigationDrawer } from "../../menu/material/navigationDrawer/NavigationDrawer";
import { MaterialFooter } from "./materialFooter/MaterialFooter";
import { RegisterModal } from "../../modals/material/registerModal/RegisterModal";
import { LoginModal } from "../../modals/material/loginModal/LoginModal";
import { useLoggedUser } from "../../../../hooks/user/useLoggedUser";
import { Navbar, NavbarMobile } from "../../menu/material/Navbar";
import { SearchBarMobile } from "../../menu/material/SearchBarMobile";
import { BottomAppBar } from "../../menu/material/BottomAppBar";
import { MaterialThemeProvider } from "./MaterialThemeProvider";
import { useRouter } from "next/router";
import NextTopLoader from "nextjs-toploader";
import { SnackbarProvider, SnackbarWrapper } from "../../snackbar";
import { ModalProvider } from "../../modals";

export const MaterialLayout = ({ children }: IMaterialLayout) => {
  useLoggedUser();

  const theme = useSelector((state: RootState) => state.theme);
  const menu = useSelector((state: RootState) => state.menu.value);
  const userRedux = useSelector((state: RootState) => state.user.value);
  const router = useRouter();
  const isOnLandingPages =
    !userRedux.loggedUser &&
    (router.pathname === "/" ||
      router.pathname === "/coach" ||
      router.pathname === "/featured-coach");

  return (
    <MaterialThemeProvider>
      <ModalProvider>
        <SnackbarProvider>
          <SnackbarWrapper>
            <NextTopLoader
              color={theme.componentColors.primary.primary}
              showSpinner={false}
            />

            <div className={"scrollbar-hide"}>
              <RegisterModal />
              <LoginModal />

              <Paper
                className="min-h-[100vh] scrollbar-hide"
                sx={{
                  backgroundColor: theme.componentColors.surface.surfContainer,
                  backgroundImage: "none",
                }}
              >
                {userRedux.loggedUser && <NavigationRail />}

                <div
                  className={`${userRedux.loggedUser ? "lg:ml-[100px]" : ""}`}
                >
                  {isOnLandingPages ? <NavbarMobile /> : <SearchBarMobile />}

                  <Navbar />

                  <Stack className={"scrollbar-hide"}>
                    {userRedux.loggedUser && <NavigationDrawer />}
                    <Stack
                      className={`pb-[100px] lg:pb-0 ${
                        menu.showDrawer
                          ? "lg:w-[79%] lg:pl-[18%] 2xl:w-[80%] 2xl:pl-[15%]"
                          : ""
                      } max-w-[1600px] w-[94%] mx-auto ${
                        userRedux.loggedUser ? "lg:w-[calc(100%-4.75rem)]" : ""
                      } transition-all mt-[5rem] lg:mt-[5.5rem] `}
                    >
                      {children}
                      <MaterialFooter />
                    </Stack>
                  </Stack>

                  <BottomAppBar />
                </div>
              </Paper>
            </div>
          </SnackbarWrapper>
        </SnackbarProvider>
      </ModalProvider>
    </MaterialThemeProvider>
  );
};
