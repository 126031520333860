import { ISignUp } from "../../interfaces/auth/ISignUp";
import { IUserData } from "../../interfaces/user/IUserData";
import { fetcher } from "../../lib/fetcher";

async function signup(params: ISignUp): Promise<IUserData> {
  return (await fetcher("auth/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  }).catch((e: Error) => {
    throw e;
  })) as IUserData;
}

async function list(user: IUserData): Promise<IUserData> {
  if (user.id && user.token) {
    return (await fetcher(`users/${user?.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
    }).catch((e: Error) => {
      console.error(e);
    })) as IUserData;
  } else {
    return;
  }
}

async function update(user: IUserData, data: unknown) {
  if (user.id) {
    return (await fetcher(`users/${user?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(data),
    }).catch((e: Error) => {
      console.error(e);
    })) as IUserData;
  } else {
    return;
  }
}

async function resetPassword(params: { email: string }) {
  return (await fetcher(
    "auth/forgot",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    },
    false,
  ).catch((e: Error) => {
    throw e;
  })) as IUserData;
}

export { list, update, signup, resetPassword };

export const config = {
  api: {
    bodyParser: false,
  },
};
