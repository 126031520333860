import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { Stack } from "@mui/material";
import { cn } from "../../../../../helpers/cn";

type NavbarWrapperProps = React.ComponentProps<"div">;

export const NavbarWrapper = ({ children, className }: NavbarWrapperProps) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors,
  );
  const userRedux = useSelector((state: RootState) => state.user.value);

  return (
    <Stack
      sx={{
        backgroundColor: componentColors.surface.surfContainer,
      }}
      className={cn(
        "hidden lg:flex fixed top-0 right-0 h-[5.5rem] z-50",
        userRedux.loggedUser ? "w-[calc(100%-6.2rem)] " : "w-full",
        className,
      )}
    >
      <div
        className={cn(
          "flex justify-between items-center  h-full w-[calc(94%)] mx-auto 2xl:max-w-[1600px]",
        )}
      >
        {children}
      </div>
    </Stack>
  );
};
