import { ListingsFromApi } from "../interfaces/training-plan/ListingsFromApi";
import { IUserData } from "../interfaces/user/IUserData";
import { fetcher } from "../lib/fetcher";
import * as Sentry from "@sentry/nextjs";
import { buildUrlName, getCookie } from "../lib/generic";
import { Listing } from "../interfaces/training-plan/Listing";
import { GetOwnedListings } from "../interfaces/training-plan/GetOwnedListings";
import { ISwapsAPI } from "../interfaces/training-plan/ISwapsAPI";
import { ILibraryListing } from "../interfaces/training-plan/ILibraryListing";
import { BlogsFromAPI } from "../interfaces/blog/BlogsFromAPI";
import Error from "../components/global/error/error";

export enum SaveListingOptions {
  PUT = "PUT",
  DELETE = "DELETE",
}

export function getFreeWorkouts(query = "", page = 0) {
  return fetcher(
    `listings?status=approved&publicListing=true&type=training&featured=true&searchLimit=10${
      page ? `&searchPage=${page}` : ""
    }${query}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).catch((e: Error) => {
    Sentry.captureException({
      category: "workouts",
      message: "error gettint listings on workouts page",
      data: e,
    });
    throw e;
  }) as Promise<ListingsFromApi>;
}

export function getListings(query = "", page = 0) {
  return fetcher(
    `listings?status=approved&publicListing=false&type=training&searchLimit=10&featured=true&searchPage=${page}${query}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).catch((e: Error) => {
    Sentry.captureException({
      category: "listings",
      message: "error getting listings",
      data: e,
    });
    throw e;
  }) as Promise<ListingsFromApi>;
}

export function getListingsFromCoach(coachId: number, page = 0) {
  return fetcher(
    `listings?type=training&coachId=${coachId}&searchLimit=10&featured=true&searchPage=${page}`,
  ).catch((e: Error) => {
    Sentry.captureException({
      category: "listings",
      message: "error getListingsFromCoach",
      data: e,
    });
    throw e;
  }) as Promise<ListingsFromApi>;
}

export function isListingSaved(listingId: number, userToken: string) {
  return fetcher(`listings/${listingId}/wishlist`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  }).catch((e: Error) => {
    throw e;
  }) as Promise<boolean>;
}

export function isFollowing(coachId: number, userToken: string) {
  return fetcher(`users/${coachId}/following`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  }).catch((e: Error) => {
    Sentry.captureException({
      category: "isFollowing",
      message: "error on isFollowing",
      data: e,
    });
    throw e;
  }) as Promise<boolean>;
}

export function getOwnedListings(user: IUserData) {
  return fetcher(`listings?owned=true&type=training`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  }).catch((e: Error) => {
    Sentry.captureException({
      category: "listings",
      message: "error on getOwnedListings",
      data: e,
    });
    throw e;
  }) as Promise<ListingsFromApi>;
}

export function getBorrowedListings(user: IUserData) {
  return fetcher(`listings/borrowed`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  }).catch((e: Error) => {
    Sentry.captureException({
      category: "listings",
      message: "error on getOwnedListings",
      data: e,
    });
    throw e;
  }) as Promise<ILibraryListing[]>;
}

export async function getUrlOneTime(user: IUserData, listing: Listing) {
  const cookie =
    getCookie("_ga").split(".")[2] + "." + getCookie("_ga").split(".")[3];
  return fetcher(
    `listings/${listing.id}/purchase?deviceOs=web&trainingPlanId=${
      listing.id
    }&trainingPlanName=${buildUrlName(listing.title)}&affiliate=${
      listing.user.name
    }&clientId=${cookie}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
    },
  ).catch((e: Error) => {
    Sentry.captureException({
      category: "listings",
      message: "error on getUrlOneTime",
      data: e,
    });

    throw e;
  }) as Promise<GetOwnedListings>;
}

export async function getSwaps(user: IUserData) {
  return fetcher(`listings/swaps?type=training`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user?.token}`,
    },
  }).catch((e: Error) => {
    Sentry.captureException({
      category: "listings",
      message: "error on getSwaps",
      data: e,
    });

    throw e;
  }) as Promise<ISwapsAPI>;
}

export async function executePurchase(
  listing: Listing,
  user: IUserData,
  listingInLibraryToSwap?: number,
) {
  return fetcher(`listings/${listing.id}/swap`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    body: JSON.stringify({
      listingInLibraryToSwap: listingInLibraryToSwap,
    }),
  }).catch((e: Error) => {
    Sentry.captureException({
      category: "listings",
      message: "error on executePurchase",
      data: e,
    });
    throw e;
  }) as Promise<ISwapsAPI>;
}

export async function updateSaveListingWishlist(
  listingId: number,
  method: SaveListingOptions,
  token: string,
) {
  return fetcher(
    `listings/${listingId}/wishlist`,
    {
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    false,
  ).catch((error: Error) => {
    Sentry.captureException({
      category: "listings",
      message: "error on updateSaveListingWishlist",
      data: error,
    });

    throw error;
  });
}

export async function getListingsFromWishlist(token: string) {
  return fetcher(`listings/wishlist?type=training`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).catch((error: Error) => {
    Sentry.captureException({
      category: "listings",
      message: "error on getListingsFromWishlist",
      data: error,
    });

    throw error;
  }) as Promise<ListingsFromApi>;
}

export function getAssessments(query?: string, page = 0) {
  if (!query) {
    query = "";
  }

  return fetcher(
    `listings?status=approved&publicListing=false&type=training&searchLimit=10&searchPage=${page}${query}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).catch((e: Error) => {
    Sentry.captureException({
      category: "listings",
      message: "error getting listings",
      data: e,
    });
    throw e;
  }) as Promise<ListingsFromApi>;
}

type ScheduleData = {
  athleteId: number;
  trainingPlanId: number;
  startDate: string;
  type: string;
  removeFutureSchedules: boolean;
  swapFutureExercises: boolean;
  title: string;
  trainingSchedule: number[];
  fromPaidTrainingPlan: boolean;
};

export async function scheduleWorkout(
  user: IUserData,
  scheduleData: ScheduleData,
) {
  try {
    return fetcher(
      `calendar`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(scheduleData),
      },
      false,
    );
  } catch (e) {
    throw e;
  }
}

export function getBlogs(query = "", page = 0) {
  return fetcher(
    `listings?status=approved&type=document&searchLimit=5&featured=true&searchPage=${page}${query}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).catch((e: Error) => {
    throw e;
  }) as Promise<BlogsFromAPI>;
}

export function getCoachesFollowingBlogs(
  user: IUserData,
  query = "",
  page = 0,
) {
  return fetcher(
    `listings/document/followed?searchLimit=5&searchPage=${page}${query}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    },
  ).catch((e: Error) => {
    throw e;
  }) as Promise<BlogsFromAPI>;
}

export function isBlogSaved(listingId: number, userToken: string) {
  return fetcher(`listings/${listingId}/library`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  }).catch((e: Error) => {
    throw e;
  }) as Promise<boolean>;
}

export async function putBlog(user: IUserData, blogId: number) {
  return fetcher(`listings/${blogId}/library`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  })
    .then((data) => {
      return data;
    })
    .catch((e: Error) => {
      throw e;
    });
}

export async function removeBlog(user: IUserData, blogId: number) {
  return fetcher(`listings/${blogId}/library`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  })
    .then((data) => {
      return data;
    })
    .catch((e: Error) => {
      throw e;
    });
}
