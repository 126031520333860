import { useRouter } from "next/router";
import Link from "next/link";
import { TextButton } from "../../../buttons/material/textButton/TextButton";
import { PrimaryButton } from "../../../buttons/material/primaryButton/PrimaryButton";

export const NavbarCoachActions = () => {
  const router = useRouter();

  if (router.asPath.includes("/coach")) {
    return (
      <>
        <Link href="/coach#FAQ">
          <TextButton text="Learn More" className="hidden lg:block" />
        </Link>
        <Link href="https://coach.pod1um.com/auth/sign-up">
          <PrimaryButton title="Get Started" className="hidden md:block" />
        </Link>{" "}
      </>
    );
  }
};
