import { Typography, TypographyProps } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

type MaterialFooterTitleProps = TypographyProps;

export const MaterialFooterTitle = ({
  className,
  children,
  ...props
}: MaterialFooterTitleProps) => {
  const colors = useSelector((state: RootState) => state.theme.componentColors);

  return (
    <Typography
      className="text-[20px] font-medium"
      sx={{
        color: colors.surface.onSurfaceDetachable,
      }}
    >
      Get the app
    </Typography>
  );
};
