import { NavbarAthleteActions } from "./NavbarAthleteActions";
import { NavbarCoachActions } from "./NavbarCoachActions";

export const NavbarLoginButtons = () => {
  return (
    <div className={"flex items-center justify-center"}>
      <NavbarAthleteActions />
      <NavbarCoachActions />
    </div>
  );
};
