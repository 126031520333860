import { NavbarLogo } from "./NavbarLogo";
import { NavbarWrapper } from "./NavbarWrapper";
import { useDispatch } from "react-redux";
import { IconButton } from "../../../buttons/material/iconButton/IconButton";
import { SearchOutlined } from "@mui/icons-material";
import { setShowMobileModal } from "../../../../../redux/searchSlice";
import { NavbarLinksWrapper } from "./NavbarLinks/NavbarlinksWrapper";
import { NavbarLink } from "./NavbarLinks/NavbarLink";
import { SearchMobileModal } from "../Search";
import { NavbarAthleteActions } from "./NavbarAthleteActions";
import { NavbarCoachActions } from "./NavbarCoachActions";

export const NavbarMobile = () => {
  const dispatch = useDispatch();
  const showSearchHandler = () => {
    dispatch(setShowMobileModal(true));
  };

  return (
    <>
      <NavbarWrapper className="flex">
        <SearchMobileModal />
        <NavbarLogo />

        <NavbarLinksWrapper>
          <NavbarLink href="/">I&apos;m an athlete</NavbarLink>
          <NavbarLink href="/coach">I&apos;m a coach</NavbarLink>
        </NavbarLinksWrapper>

        <div className="flex items-center justify-center">
          <IconButton
            onClick={showSearchHandler}
            icon={<SearchOutlined />}
            className="flex lg:hidden w-12 h-12 "
          />

          <NavbarAthleteActions />
          <NavbarCoachActions />
        </div>
      </NavbarWrapper>
    </>
  );
};
