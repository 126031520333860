import { Modal } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import React from "react";
import { cn } from "../../../../helpers/cn";

type DefaultModalProps = {
  open?: boolean;
  onClose?: () => void;
  showBottomsheetOnlyForMobile?: boolean;
} & React.ComponentProps<"div">;

export const DefaultModal = ({
  children,
  open,
  onClose,
  showBottomsheetOnlyForMobile = false,
  className,
}: DefaultModalProps) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Modal
      open={open ?? true}
      onClose={onClose}
      sx={{
        overflow: "hidden",
        backdropFilter: "blur(0.8px)",
        WebkitBackdropFilter: "blur(0.8px)",
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: theme.componentColors.primary.onPrimary,
            opacity: "0.4 !important",
          },
        },
      }}
    >
      <div
        style={{
          backgroundColor: theme.componentColors.surface.surfContainer,
        }}
        className={cn(
          "outline-none bottom-0 absolute left-0 rounded-b-none sm:h-fit rounded-t-[28px] overflow-y-auto scrollbar-hide pb-[35px] max-h-[70vh] lg:max-h-[90vh]",
          showBottomsheetOnlyForMobile
            ? "w-full md:w-auto md:rounded-[28px] md:transform md:-translate-x-1/2 md:-translate-y-1/2 md:left-1/2 md:top-1/2 md:pb-0"
            : "w-full lg:w-auto lg:transform lg:rounded-[28px] lg:-translate-x-1/2 lg:-translate-y-1/2 lg:left-1/2 lg:top-1/2 lg:pb-0",
          className,
        )}
      >
        {children}
      </div>
    </Modal>
  );
};
