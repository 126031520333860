import { IObjective } from "../interfaces/globalComponents/filters/filterItems/IObjective";
import { ISport } from "../interfaces/globalComponents/filters/filterItems/ISport";
import {
  BodyInjury,
  MuscleArea,
} from "../interfaces/onboarding/injuries/BodyInjury";
import { EquipmentFromApi } from "../interfaces/onboarding/EquipmentFromApi";
import { IUserData } from "../interfaces/user/IUserData";
import { fetcher } from "../lib/fetcher";
import * as Sentry from "@sentry/nextjs";

export async function getInjuries(user: IUserData, bodyAreaId?: number) {
  return (await fetcher(
    `injuries${bodyAreaId ? `?bodyArea=${bodyAreaId}` : ""}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    },
  ).catch((e: Error) => {
    Sentry.captureException({
      category: "onboarding",
      message: "error getting injuries on injuries page",
      data: e,
    });
    throw e;
  })) as Promise<BodyInjury[]>;
}

export type InjuryData = {
  id?: number;
  injuryId: number;
  athleteId: number;
  notes: string;
  startDate?: number;
  endDate?: number;
};

export async function addInjury(user: IUserData, injuryData: InjuryData) {
  return fetcher(
    `users/injury`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(injuryData),
    },
    true,
  ).catch((e: Error) => {
    throw e;
  });
}

export type DeleteInjuryData = {
  injuryHistoryId: number;
  injuryId: number;
  notes: string;
  startDate: number;
  endDate: number;
};

export async function deleteInjury(user: IUserData, injuryHistoryId: number) {
  return fetcher(
    `users/${user.id}/injury?injuryHistoryId=${injuryHistoryId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    },
    true,
  ).catch((e: Error) => {
    Sentry.captureException({
      category: "onboarding",
      message: "error on deleteInjury",
      data: e,
    });
    throw e;
  });
}

export async function getBodyAreas(user: IUserData) {
  return (await fetcher("muscles/areas", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  }).catch((e: Error) => {
    Sentry.captureException({
      category: "injuryModal",
      message: "error on getting body areas",
      data: e,
    });
    throw e;
  })) as Promise<MuscleArea[]>;
}

export async function getEquipments() {
  const equipments = (await fetcher("equipment").catch((e: Error) => {
    Sentry.captureException({
      category: "profileEquipments",
      message: "error getting equipments on profile equipments page",
      data: e,
    });
    throw e;
  })) as EquipmentFromApi[];
  return equipments.filter((equipment) => equipment.includeForm);
}

export async function getSports() {
  const sports = (await fetcher("sports").catch((e: Error) => {
    Sentry.captureException({
      category: "profile-sports",
      message: "error getting sports on profile sports page",
      data: e,
    });
  })) as ISport[];
  return sports?.filter((sport) => sport.includeForm);
}

export async function getObjectives() {
  const objectives = (await fetcher("objectives").catch((e: Error) => {
    Sentry.captureException({
      category: "profile-objectives",
      message: "error getting objectives on profile-objectives page",
      data: e,
    });
  })) as IObjective[];
  return objectives?.filter((objective) => objective.includeForm);
}
