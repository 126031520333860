import { LoginWithAppleButton } from "../loginProviderButtons/LoginWithAppleButton";
import { LoginWithGoogleButton } from "../loginProviderButtons/LoginWithGoogleButton";
import { ILoginFormServiceBtns } from "../../../../interfaces/globalComponents/authComponents/login/ILoginFormServiceBtns";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useEffect } from "react";
import { setServiceErrorMessage } from "../../../../redux/loginServiceSlice";
import { LoginServiceProvider } from "../loginProviderButtons";

export const LoginFormServiceBtns = ({
  toReload = true,
}: ILoginFormServiceBtns) => {
  const error = useSelector(
    (state: RootState) => state.loginService.errorMessage,
  );
  const colors = useSelector((state: RootState) => state.theme.componentColors);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setServiceErrorMessage(""));
  }, [dispatch]);

  return (
    <div>
      {error && (
        <p style={{ color: colors.error.error }} className="text-center">
          {error}
        </p>
      )}

      <div className="flex flex-col gap-4">
        <LoginServiceProvider>
          <LoginWithGoogleButton toReload={toReload} />

          <LoginWithAppleButton toReload={toReload} />
        </LoginServiceProvider>
      </div>
    </div>
  );
};
