import { TextButton } from "../../../buttons/material/textButton/TextButton";
import { PrimaryButton } from "../../../buttons/material/primaryButton/PrimaryButton";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { setRegisterModal } from "../../../../../redux/pages/registerPageSlice";
import { setLoginModal } from "../../../../../redux/pages/loginPageSlice";

export const NavbarAthleteActions = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  if (router.asPath != "/coach" && router.asPath != "/coach#FAQ") {
    return (
      <>
        <TextButton
          text="Log In"
          className="hidden lg:block"
          onClick={() => {
            dispatch(setLoginModal());
          }}
        />
        <PrimaryButton
          title="Sign up free"
          className="hidden md:block"
          onClick={() => {
            dispatch(setRegisterModal());
          }}
        />
      </>
    );
  }
};
