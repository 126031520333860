export const MIN_NUMBER_REVIEWS = 6;
export const MAX_REVIEWS = 4;
export const MOCK_ARRAY_3 = [1, 2, 3];
export const MOCK_ARRAY_5 = [1, 2, 3, 4, 5];
export const MOCK_ARRAY_8 = [1, 2, 3, 4, 5, 6, 7, 8];
export const MOCK_ARRAY_10 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const MAX_CHARS = 180;
export const SECTION_TITLE_SIZE = 100;

export const COLLABORATOR_MOCK = [
  {
    type: "athlete",
    title: "Common Title",
    link: "https://www.nike.com",
    imageUrl: null,
  },
  {
    type: "athlete",
    title: "Common Title",
    link: "https://www.nike.com",
    imageUrl: "/images/pre-season-cycle.png",
  },
];

export const coachInterests = [
  {
    id: 1,
    title: "Strength & Conditioning",
    value: "strength",
    isCategory: true,
  },
  {
    id: 2,
    title: "Performance Nutrition",
    value: "nutrition",
    isCategory: true,
  },
  {
    id: 3,
    title: "Athletic Therapy",
    value: "therapy",
    isCategory: true,
  },
  {
    id: 4,
    title: "Sports Psychology",
    value: "psychology",
    isCategory: true,
  },
];

export const passwordRequirements =
  "Recommended:\n" +
  "• Minimum of 6 characters\n" +
  "• Uppercase and lowercase letters\n" +
  "• Numbers and Special characters (e.g., *, !, %)\n";
