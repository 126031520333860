import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Card, CardProps } from "@mui/material";
import { cn } from "../../../helpers/cn";

type IDefaultLoading = {
  width?: number;
  height?: number;
  lgHeight?: string;
  useFullWidth?: boolean;
  rounded?: string;
} & CardProps;

export const DefaultLoadingComponent = ({
  width,
  height,
  useFullWidth,
  lgHeight,
  rounded,
  className,
  ...props
}: IDefaultLoading) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Card
      sx={{
        backgroundColor: theme.componentColors.surface.surfContainerHighest,
        width: `${width}px`,
        height: `${height}px`,
      }}
      className={cn(
        `
      ${lgHeight ? lgHeight : ""}
      ${useFullWidth ? "w-full" : ""}
        ${rounded ? rounded : "rounded-2xl"} animate-pulse`,
        className,
      )}
      {...props}
    />
  );
};
