import { IconButton, Stack, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import { PrimaryButton } from "../../buttons/material/primaryButton/PrimaryButton";
import { useRouter } from "next/router";
import {
  setErrorMessages,
  setIsLoadingForm,
  setIsLoadingPage,
  setRegisterModal,
} from "../../../../redux/pages/registerPageSlice";
import { LoginFormLoadingServiceBtns } from "../login/LoginFormLoadingServiceBtns";
import { useLoggedUser } from "../../../../hooks/user/useLoggedUser";
import { LoginFormServiceBtns } from "../login/LoginFormServiceBtns";
import { signup } from "../../../../pages/api/user";
import { saveUserOnLocalStorage } from "../../../../lib/authentication";
import { IRegisterForm } from "../../../../interfaces/globalComponents/authComponents/register/IRegisterForm";
import { setIsOpenOnboardingModal } from "../../../../redux/onboardingSlice";
import { capitalizeFirstLetter } from "../../../../lib/global/capitalizeFirstLetter";
import { sendEmailToFirstPromoter } from "../../../../lib/firstPromoter/sendEmailToFp";
import { setLoginModal } from "../../../../redux/pages/loginPageSlice";
import { AuthSignupHelper } from "../AuthSignupHelper";
import { AuthSignupHelperLink } from "../AuthSignupHelperLink";

export const RegisterForm = ({ isModal = false }: IRegisterForm) => {
  const theme = useSelector((state: RootState) => state.theme);
  const [showPassword, setShowPassword] = useState(false);
  const [registerInputs, setRegisterInputs] = useState({
    email: "",
    password: "",
    name: "",
  });
  const router = useRouter();
  const registerPageRedux = useSelector(
    (state: RootState) => state.registerPage,
  );
  const dispatch = useDispatch();
  const isLoggedInUser = useSelector(
    (state: RootState) => state.user.value.loggedUser,
  );
  const isLoadingForm = useSelector(
    (state: RootState) => state.registerPage.isLoadingForm,
  );
  const { page } = router.query;

  useLoggedUser("/profile");

  useEffect(() => {
    if (!isLoggedInUser && isLoggedInUser !== null) {
      dispatch(setIsLoadingPage(false));
    }
  }, [isLoggedInUser, router, dispatch]);

  const handleSubmit = () => {
    if (registerInputs.name.trim().length < 3) {
      dispatch(
        setErrorMessages({
          nameErrorMessage: "Enter a valid name",
        }),
      );
      return;
    }
    if (registerInputs.email.trim().length === 0) {
      dispatch(
        setErrorMessages({
          emailErrorMessage: "Enter a valid email",
        }),
      );
      return;
    }
    if (registerInputs.password.trim().length < 6) {
      dispatch(
        setErrorMessages({
          passwordErrorMessage: "Enter a longer password",
        }),
      );
      return;
    }

    dispatch(setIsLoadingForm(true));

    signup({
      firstName: registerInputs.name,
      email: registerInputs.email,
      password: registerInputs.password,
    })
      .then((res) => {
        if (res.token && page && !isModal) {
          void router.push(page as string);
        }

        if (res.token && !page && !isModal && window?.innerWidth < 768) {
          void router.push("/onboarding");
        }

        if (res.token && !page && !isModal && window?.innerWidth >= 768) {
          void router.push("/");
          dispatch(setIsOpenOnboardingModal(true));
        }

        if (res.token && isModal) {
          router.reload();
        }

        isModal && dispatch(setRegisterModal());
        res.token && saveUserOnLocalStorage(res);

        sendEmailToFirstPromoter(registerInputs.email);
      })
      .catch((error: Error) => {
        dispatch(setIsLoadingForm(false));
        dispatch(
          setErrorMessages({
            formErrorMessage: error.message,
          }),
        );
      });
  };

  return (
    <Stack
      sx={{
        backgroundColor: theme.componentColors.surface.surfContainer,
      }}
      className="px-5 pt-5 md:px-0 md:py-14 flex flex-col items-center justify-center rounded-[12px] md:w-[788px]"
    >
      <Stack className="w-full md:w-[530px] mx-auto">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Stack className="space-y-[16px] w-full">
            <div>
              <Typography
                className="text-2xl font-medium first-letter:text-left"
                sx={{
                  color: theme.componentColors.surface.onSurfaceDetachable,
                }}
              >
                Athlete sign up
              </Typography>
              <AuthSignupHelper
                useMxAuto={false}
                className={"font-medium text-left text-sm"}
              >
                Are you a coach?{" "}
                <span className={"text-sm font-normal"}>Get started</span>{" "}
                <AuthSignupHelperLink
                  href={`${process.env.NEXT_PUBLIC_COACH_APP}`}
                  style={{
                    fontWeight: 400,
                  }}
                >
                  here
                </AuthSignupHelperLink>
              </AuthSignupHelper>
            </div>

            <Stack>
              <TextField
                disabled={registerPageRedux.isLoadingForm}
                type="text"
                value={registerInputs.name}
                onChange={(event) => {
                  dispatch(setErrorMessages({ nameErrorMessage: "" }));
                  setRegisterInputs({
                    ...registerInputs,
                    name: capitalizeFirstLetter(event.target.value),
                  });
                }}
                className="rounded-lg h-14"
                placeholder="Name"
              />
              <Typography
                sx={{
                  color: theme.componentColors.error.error,
                }}
                className="text-[12px]"
              >
                {registerPageRedux.errorMessages.nameErrorMessage}
              </Typography>
            </Stack>
            <Stack>
              <TextField
                disabled={registerPageRedux.isLoadingForm}
                type="email"
                value={registerInputs.email}
                onChange={(event) => {
                  dispatch(setErrorMessages({ emailErrorMessage: "" }));
                  setRegisterInputs({
                    ...registerInputs,
                    email: event.target.value.toLowerCase(),
                  });
                }}
                className="rounded-lg h-14"
                placeholder="E-mail"
              />
              <Typography
                sx={{
                  color: theme.componentColors.error.error,
                }}
                className="text-[12px]"
              >
                {registerPageRedux.errorMessages.emailErrorMessage}
              </Typography>
            </Stack>
            <Stack>
              <TextField
                disabled={registerPageRedux.isLoadingForm}
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={registerInputs.password}
                className="rounded-lg"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon
                          className="text-[20px]"
                          sx={{
                            color: theme.componentColors.primary.primary,
                          }}
                        />
                      ) : (
                        <VisibilityIcon
                          className="text-[20px]"
                          sx={{
                            color: theme.componentColors.primary.primary,
                          }}
                        />
                      )}
                    </IconButton>
                  ),
                }}
                onChange={(event) => {
                  setRegisterInputs({
                    ...registerInputs,
                    password: event.target.value,
                  });

                  dispatch(
                    setErrorMessages({
                      passwordErrorMessage: "",
                    }),
                  );
                }}
              />
              <Typography
                sx={{
                  color: theme.componentColors.error.error,
                }}
                className="text-[12px]"
              >
                {registerPageRedux.errorMessages.passwordErrorMessage}
              </Typography>
            </Stack>

            {registerPageRedux.isLoadingPage ? (
              <div
                style={{
                  backgroundColor:
                    theme.componentColors.surface.surfContainerHighest,
                }}
                className="w-full rounded-2xl h-[40px] animate-pulse"
              />
            ) : (
              <PrimaryButton
                disabled={registerPageRedux.isLoadingForm}
                title="Create Account"
                type="submit"
              />
            )}
            <Typography
              sx={{
                color: theme.componentColors.error.error,
              }}
              className="text-[14px] text-center"
            >
              {registerPageRedux.errorMessages.formErrorMessage}
            </Typography>
          </Stack>
        </form>

        <Stack className="w-full space-y-[16px]">
          <div className="flex items-center justify-around w-full space-x-[2px] my-2">
            <div
              style={{
                backgroundColor: theme.componentColors.outline.outlineVariant,
              }}
              className="w-28 md:w-44 h-[0.5px]"
            />
            <Typography className="text-xs md:text-sm text-center ">
              or continue with
            </Typography>
            <div
              style={{
                backgroundColor: theme.componentColors.outline.outlineVariant,
              }}
              className="w-28 md:w-44 h-[0.5px]"
            />
          </div>
          <div>
            {registerPageRedux.isLoadingPage ? (
              <LoginFormLoadingServiceBtns />
            ) : (
              <LoginFormServiceBtns
                isLoadingForm={isLoadingForm}
                toReload={true}
              />
            )}
          </div>
        </Stack>
      </Stack>
      <AuthSignupHelper className={"mt-9"}>
        Already have an account?{" "}
        {isModal ? (
          <span
            style={{
              color: theme.componentColors.primary.primary,
            }}
            className="cursor-pointer"
            onClick={() => {
              dispatch(setRegisterModal());
              dispatch(setLoginModal());
            }}
          >
            Login
          </span>
        ) : (
          <AuthSignupHelperLink href="/login">Login</AuthSignupHelperLink>
        )}
      </AuthSignupHelper>
    </Stack>
  );
};
