import { Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { cn } from "../../../helpers/cn";

type AuthSignupHelperProps = {
  useMxAuto?: boolean;
} & React.ComponentProps<"p">;

export const AuthSignupHelper = ({
  children,
  className,
  useMxAuto = true,
}: AuthSignupHelperProps) => {
  const colors = useSelector((state: RootState) => state.theme.componentColors);

  return (
    <div className={cn(useMxAuto ? "mx-auto" : "")}>
      <Typography
        className={className}
        sx={{
          color: colors.surface.onSurface,
          fontSize: "0.8rem",
        }}
      >
        {children}
      </Typography>
    </div>
  );
};
