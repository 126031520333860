import React from "react";
import { NavbarLink } from "./NavbarLink";
import { NavbarLinksWrapper } from "./NavbarlinksWrapper";

export const NavbarLinks = () => {
  return (
    <NavbarLinksWrapper>
      <NavbarLink href="/">Home</NavbarLink>
      <NavbarLink href="/explore">I&apos;m an athlete</NavbarLink>
      <NavbarLink href="/coach" className="hidden lg:inline">
        I&apos;m a coach
      </NavbarLink>
    </NavbarLinksWrapper>
  );
};
