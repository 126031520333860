import { InputBase, List, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { ArrowBack, Close } from "@mui/icons-material";
import { IconButton } from "../../../buttons/material/iconButton/IconButton";
import { searchByString } from "../../../../../api/search";
import * as Sentry from "@sentry/nextjs";
import { ListingsFromApi } from "../../../../../interfaces/training-plan/ListingsFromApi";
import { CoachesFromApi } from "../../../../../interfaces/specialist/CoachesFromApi";
import { SearchMenuItem } from "./SearchMenuItem";
import { MOCK_ARRAY_10 } from "../../../../../lib/constants";
import { DefaultLoadingComponent } from "../../../defaultLoading/DefaultLoadingComponent";
import { buildUrlName } from "../../../../../lib/generic";
import { addSearchTerm } from "../../../../../redux/filtersSlice";
import { useSearchHistory } from "../../../../../hooks/user/useSearchHistory";
import { SearchMenuItemHistory } from "./SearchMenuItemHistory";
import { useRouter } from "next/router";
import { setShowMobileModal } from "../../../../../redux/searchSlice";

export const SearchMobileModal = () => {
  const showMobileModal = useSelector(
    (state: RootState) => state.search.showMobileModal,
  );
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors,
  );
  const [inputValue, setInputValue] = useState("");
  const [debounceValue, setDebounceValue] = useState("");
  const dispatch = useDispatch();
  const [resultListings, setResultListings] = useState<ListingsFromApi>({
    rows: [],
    count: null,
  });
  const [resultCoaches, setResultCoaches] = useState<CoachesFromApi>({
    rows: [],
    count: null,
  });
  const [resultWorkouts, setResultWorkouts] = useState<ListingsFromApi>({
    rows: [],
    count: null,
  });
  const [resultBlogs, setResultBlogs] = useState<ListingsFromApi>({
    rows: [],
    count: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const searchHistory = useSearchHistory();
  const router = useRouter();

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebounceValue(inputValue);
    }, 300);

    return () => clearTimeout(timer);
  }, [inputValue]);

  useEffect(() => {
    if (debounceValue) {
      setIsLoading(true);
      void handleInputChange(debounceValue);
    }
  }, [debounceValue]);

  const handleInputChange = async (searchText: string) => {
    return searchByString(searchText)
      .then((value) => {
        setIsLoading(false);
        setResultListings(value[0]);
        setResultCoaches(value[1]);
        setResultWorkouts(value[2]);
        setResultBlogs(value[3]);
      })
      .catch((error: Error) => {
        Sentry.captureException({
          category: "SearchMobileModal",
          message: "error on handleInputChange",
          data: error,
        });
      });
  };

  const handleClear = () => {
    setInputValue("");
    setDebounceValue("");
    setResultListings({
      rows: [],
      count: null,
    });
    setResultCoaches({
      rows: [],
      count: null,
    });
    setResultWorkouts({
      rows: [],
      count: null,
    });
    setResultBlogs({
      rows: [],
      count: null,
    });
  };

  const handleClickHistoryItem = (historyItem: string) => {
    dispatch(addSearchTerm({ searchTerm: historyItem, addInHistory: false }));
    setInputValue("");
    dispatch(setShowMobileModal(false));
  };

  if (!showMobileModal) {
    return;
  }

  return (
    <Stack
      sx={{
        backgroundColor: componentColors.surface.surfContainer,
      }}
      className="fixed top-0 left-0 w-screen h-screen z-50 items-start"
    >
      <Stack
        sx={{
          borderBottom: "1px solid",
          borderBottomColor: componentColors.outline.outline,
        }}
        direction={"row"}
        className="items-center justify-between w-full py-3"
      >
        <Stack direction={"row"} className="items-center w-full">
          <IconButton
            onClick={() => {
              dispatch(setShowMobileModal(false));
            }}
            icon={
              <ArrowBack sx={{ color: componentColors.surface.onSurface }} />
            }
          />

          <InputBase
            autoFocus
            placeholder="Search Content & Services"
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            className="w-full"
            onKeyPress={(event) => {
              if (event.key === "Enter" && inputValue.length > 0) {
                dispatch(
                  addSearchTerm({
                    searchTerm: inputValue,
                    addInHistory: searchHistory.includes(inputValue.trim())
                      ? false
                      : true,
                  }),
                );
                setInputValue("");
                dispatch(setShowMobileModal(false));

                if (!router.asPath.includes("explore")) {
                  void router.push("/explore");
                }
              }
            }}
            value={inputValue}
          />
        </Stack>

        {inputValue.length > 0 && (
          <IconButton
            onClick={handleClear}
            icon={<Close sx={{ color: componentColors.surface.onSurface }} />}
          />
        )}
      </Stack>

      <List className="overflow-y-scroll w-full space-y-[14px]">
        <List className="flex flex-col-reverse py-0">
          {searchHistory.map((item, i) => {
            return (
              <SearchMenuItemHistory
                key={i}
                item={item}
                onClick={() => handleClickHistoryItem(item)}
              />
            );
          })}
        </List>

        {!isLoading &&
          resultListings.rows.map((item) => {
            return (
              <div
                key={item.id}
                onClick={() => dispatch(setShowMobileModal(false))}
              >
                <SearchMenuItem
                  key={item.id}
                  title={item.title}
                  image={item.coverImage}
                  subtitle="Training Plan"
                  link={`/training-plan/${item.id}/${buildUrlName(item.title)}`}
                />
              </div>
            );
          })}

        {!isLoading &&
          resultCoaches.rows.map((item) => {
            return (
              <div
                key={item.id}
                onClick={() => dispatch(setShowMobileModal(false))}
              >
                <SearchMenuItem
                  key={item.id}
                  title={item.name}
                  image={item.avatarUrl}
                  subtitle="Coach"
                  link={`/specialist/${item.id}/${buildUrlName(item.name)}`}
                />
              </div>
            );
          })}

        {!isLoading &&
          resultWorkouts.rows.map((item) => {
            return (
              <div
                key={item.id}
                onClick={() => dispatch(setShowMobileModal(false))}
              >
                <SearchMenuItem
                  key={item.id}
                  title={item.title}
                  image={item.coverImage}
                  subtitle="Workout"
                  link={`/training-plan/${item.id}/${buildUrlName(item.title)}`}
                />
              </div>
            );
          })}

        {!isLoading &&
          resultBlogs.rows.map((item) => {
            return (
              <div
                key={item.id}
                onClick={() => dispatch(setShowMobileModal(false))}
              >
                <SearchMenuItem
                  key={item.id}
                  title={item.title}
                  image={item.coverImage}
                  subtitle="Blog"
                  link={`/blog/${item.id}/${buildUrlName(item.title)}`}
                />
              </div>
            );
          })}
        <div className="px-4 space-y-[14px]">
          {isLoading
            ? MOCK_ARRAY_10.map((item) => (
                <DefaultLoadingComponent key={item} height={50} />
              ))
            : null}
        </div>
      </List>
    </Stack>
  );
};
