import React from "react";
import Link from "next/link";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

type AuthSignupHelperLinkProps = React.ComponentProps<"a">;

export const AuthSignupHelperLink = ({
  children,
  className,
  href,
  style,
}: AuthSignupHelperLinkProps) => {
  const colors = useSelector((state: RootState) => state.theme.componentColors);

  return (
    <Link
      className={className}
      style={{
        color: colors.primary.primary,
        fontSize: "0.875rem",
        fontWeight: 600,
        ...style,
      }}
      href={href}
    >
      {children}
    </Link>
  );
};
