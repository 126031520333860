import { Stack, Typography } from "@mui/material";
import { Pod1umLogo } from "../../../logo/Pod1umLogo";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import { MaterialFooterSocials } from "./MaterialFooterSocials";
import { MaterialFooterHelpLinks } from "./MaterialFooterHelpLinks";
import Link from "next/link";
import { MaterialFooterWrapper } from "./MaterialFooterWrapper";
import { MaterialFooterTitle } from "./MaterialFooterTitle";
import MobileStores from "../../../MobileStores/MobileStores";

export const MaterialFooter = () => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <div className={"flex mt-[100px]"}>
      <div className={"flex flex-col space-y-[100px] w-full"}>
        <div className="flex flex-col space-y-8 lg:flex-row ">
          <div className="space-y-[24px] lg:w-1/3">
            <div className="w-[80px] h-[95px]">
              <Pod1umLogo />
            </div>

            <Typography
              sx={{
                color: theme.componentColors.surface.onSurface,
              }}
            >
              Experience the Pod1um difference, try a free workout today.{" "}
              <br className="lg:hidden" />
              Your journey to peak performance starts here.
            </Typography>
            <MaterialFooterSocials />
          </div>

          <div className="flex flex-col gap-5 md:flex-row md:w-2/3 lg:justify-around">
            <MaterialFooterHelpLinks
              title="Coaches"
              links={[
                {
                  title: "Free account",
                  link: "https://coach.pod1um.com/auth/sign-up",
                },

                {
                  title: "Get featured",
                  link: "https://pod1um.com/featured-coach",
                },

                {
                  title: "Support",
                  link: "https://support.pod1um.com",
                },
              ]}
            />
            <MaterialFooterHelpLinks
              title="Athletes"
              links={[
                {
                  title: "Learn more",
                  link: "https://join.pod1um.com/athlete",
                },
                {
                  title: "Explore",
                  link: "https://pod1um.com/explore",
                },
                {
                  title: "Pricing",
                  link: "https://pod1um.com/payment",
                },
              ]}
            />
            <MaterialFooterHelpLinks
              title="More"
              links={[
                {
                  title: "Free mobile app",
                  link: "https://join.pod1um.com/app",
                },
                {
                  title: "Contact us",
                  link: "mailto:support@pod1um.com",
                },
                {
                  title: "Blog",
                  link: "https://join.pod1um.com/posts",
                },
              ]}
            />
            <MaterialFooterWrapper className="hidden lg:flex">
              <MaterialFooterTitle>Get the app</MaterialFooterTitle>

              <MobileStores useWhiteIcons={true} />
            </MaterialFooterWrapper>
          </div>
          <MaterialFooterWrapper className="flex lg:hidden">
            <MaterialFooterTitle>Get the app</MaterialFooterTitle>

            <MobileStores useWhiteIcons={true} />
          </MaterialFooterWrapper>
        </div>

        <Stack
          sx={{
            borderTop: "0.5px solid",
            borderTopColor: theme.componentColors.surface.onSurface,
          }}
          className="flex flex-col lg:flex-row justify-between items-center py-[20px]"
        >
          <Typography className="text-[18px] font-light ">
            Copyright &#169; 2024 Pod1um
          </Typography>

          <div className="flex items-center space-x-2 px-4">
            <Typography className="text-[18px] font-light">
              All rights Reserved
            </Typography>
            <Typography> | </Typography>
            <Link href="https://join.pod1um.com/terms/">
              <Typography
                className="text-[18px] font-light "
                sx={{
                  color: theme.componentColors.primary.primary,
                }}
              >
                Terms and Conditions
              </Typography>
            </Link>
            <Typography> | </Typography>
            <Link href="https://join.pod1um.com/terms/">
              <Typography
                sx={{
                  color: theme.componentColors.primary.primary,
                }}
                className="text-[18px] font-light"
              >
                {" "}
                Privacy Policy
              </Typography>
            </Link>
          </div>
        </Stack>
      </div>
    </div>
  );
};
