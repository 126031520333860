import { IoMdClose } from "react-icons/io";
import { RiArrowDownSLine } from "react-icons/ri";
import React from "react";

type DefaultModalProps = {
  closeHandler: () => void;
  title: string;
  onClick?: () => void;
} & React.ComponentProps<"div">;

export const Modal = ({
  closeHandler,
  children,
  title = "Modal",
  onClick = () => {
    return;
  },
}: DefaultModalProps) => {
  return (
    <>
      <div
        onClick={() => {
          closeHandler();
        }}
        className="flex fixed inset-0 h-screen w-full z-40 bg-blue-400 bg-opacity-80"
      />

      <div
        onClick={onClick}
        className="w-screen z-50 injuries-modal h-2/3 sm:h-96 lg:h-auto
        text-sm overflow-auto scrollbar-hide fixed flex flex-col
        bg-black-700 rounded-t-2xl lg:rounded-b-2xl p-5 md:p-14
        bottom-0 left-0 transform md:-translate-x-1/2 md:translate-y-1/2 md:bottom-1/2 md:left-1/2"
      >
        <button
          onClick={() => {
            closeHandler();
          }}
          className="md:hidden self-center hover:bg-black-500 rounded-full"
        >
          <RiArrowDownSLine className="text-4xl " />
        </button>

        <div className="flex items-start justify-between ">
          <h3 className="text-3xl font-semibold ">{title}</h3>

          <button
            onClick={() => {
              closeHandler();
            }}
            className="hidden md:block "
          >
            <IoMdClose className="text-4xl hover:text-blue-500 transition-all" />
          </button>
        </div>
        {children}
      </div>
    </>
  );
};
