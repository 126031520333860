import { cn } from "../../../../../helpers/cn";
import React from "react";

type MaterialFooterWrapperProps = React.ComponentProps<"div">;

export const MaterialFooterWrapper = ({
  className,
  children,
  ...props
}: MaterialFooterWrapperProps) => {
  return (
    <div
      className={cn(
        "flex flex-col items-center  space-y-5 lg:space-y-10",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
