import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useState,
} from "react";

type ModalContextType = {
  modals: ReactNode;
  showModal: Dispatch<React.SetStateAction<ReactNode>>;
  closeModal: () => void;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [modals, setModals] = useState<ReactNode[]>([]);

  const showModal = (modal: React.ReactNode) => {
    setModals((prev) => [...prev, modal]);
  };

  const closeModal = () => {
    setModals((prev) => prev.slice(0, -1));
  };

  return (
    <ModalContext.Provider
      value={{
        modals,
        closeModal,
        showModal,
      }}
    >
      {children}
      {modals.map((modal) => modal)}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("useModalContext must be used within ModalProvider");
  }

  return context;
};
