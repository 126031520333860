import { AppSecondaryButton, AppSecondaryButtonProps } from "../ui/buttons";
import React from "react";
import { cn } from "../../../helpers/cn";

export const ModalActionCancel = ({
  className,
  ...props
}: AppSecondaryButtonProps) => {
  return (
    <AppSecondaryButton
      className={cn("flex-auto w-[82px]", className)}
      {...props}
    >
      Cancel
    </AppSecondaryButton>
  );
};
