import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { PersonOutline } from "@mui/icons-material";
import { setRegisterModal } from "../../../../../redux/pages/registerPageSlice";

export const SearchBarMobileNotLoggedUser = () => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors,
  );
  const dispatch = useDispatch();

  return (
    <div
      style={{
        backgroundColor: componentColors.primary.primary,
      }}
      className="w-8 h-8 rounded-full flex items-center justify-center"
      onClick={() => {
        dispatch(setRegisterModal());
      }}
    >
      <PersonOutline
        sx={{
          color: componentColors.primary.onPrimary,
        }}
      />
    </div>
  );
};
