import { getSpecialists } from "./coaches";
import { getBlogs, getFreeWorkouts, getListings } from "./listings";

export const searchByString = (searchTerm: string) => {
  const searchQuery = `&searchQuery=${searchTerm}`;

  return Promise.all([
    getListings(searchQuery),
    getSpecialists(searchQuery),
    getFreeWorkouts(searchQuery),
    getBlogs(searchQuery),
  ]);
};
