import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { Button } from "@mui/material";
import { ICustomButton } from "../../../../../interfaces/globalComponents/buttons/material/ICustomButton";

export const PrimaryButton = ({
  title,
  className,
  onClick,
  startIcon,
  disabled,
  type = "button",
  sx,
}: ICustomButton) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Button
      type={type}
      className={className}
      onClick={() => (onClick ? void onClick() : null)}
      startIcon={startIcon}
      disabled={disabled}
      sx={{
        borderRadius: "1.5rem",
        padding: "0 24px",
        height: "2.5rem",
        fontWeight: "500",
        textTransform: "none",
        fontSize: "0.875rem",
        backgroundColor: theme.componentColors.primary.primary,
        backgroundImage: "none",
        color: theme.componentColors.primary.onPrimary,
        "&:hover": {
          backgroundColor: theme.componentColors.primary.primary,
          color: theme.componentColors.primary.onPrimary,
        },
        "&:disabled": {
          backgroundColor: theme.componentColors.primary.primary,
          color: theme.componentColors.primary.onPrimary,
        },
        opacity: disabled ? 0.5 : 1,
        ...sx,
      }}
    >
      {title}
    </Button>
  );
};
