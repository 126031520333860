import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { cn } from "../../../helpers/cn";
import React from "react";

type Pod1umFullLogoProps = React.ComponentProps<"div">;

export const Pod1umFullLogo = ({ className }: Pod1umFullLogoProps) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <div className={cn("w-[200px] lg:w-[350px] 2xl:w-[550px]", className)}>
      {theme.mode === "dark" ? (
        <img
          alt="Pod1um Full Logo"
          title="Pod1um Full Logo"
          className="w-full h-full object-contain"
          src={`/images/pod1um-full-white-logo.png`}
        />
      ) : (
        <img
          alt="Pod1um Full Logo"
          title="Pod1um Full Logo"
          className="w-full h-full object-contain "
          src={`/images/pod1um-full-black-logo.png`}
        />
      )}
    </div>
  );
};
