import { useEffect, useState } from "react";
import { getObjectives, getSports } from "../../api/onboarding";
import { IDrawerFilters } from "../../interfaces/globalComponents/layout/material/NavigationDrawer/IDrawerFilters";
import { allInterests, allLevels } from "../../lib/genericFilterData";
import { useDispatch } from "react-redux";
import { setFiltersList } from "../../redux/pages/exploreSlice";

export const useFilters = () => {
  const [filters, setFilters] = useState<IDrawerFilters>({
    sports: [],
    objectives: [],
    interests: [...allInterests],
    levels: [...allLevels],
  });
  const dispatch = useDispatch();

  useEffect(() => {
    getObjectives()
      .then((objectives) => {
        setFilters((prev) => {
          return { ...prev, objectives };
        });
      })
      .catch((error: Error) => {
        console.error(error);
      });

    getSports()
      .then((sports) => {
        setFilters((prev) => {
          return { ...prev, sports };
        });
      })
      .catch((error: Error) => {
        console.error(error);
      });
  }, [dispatch]);

  dispatch(setFiltersList(filters));

  return filters;
};
